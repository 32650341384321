const CopyService = {
  copy: copy,
  copyJsonUrl,
  copyWindowUrl
};

function copyJsonUrl(jsonToken) {
  copy(`https://json.link/${jsonToken}.json`)
}

function copyWindowUrl(editToken) {
  copy(`https://json.link/${editToken}`)
}

function copy(text) {
  var input = document.createElement('input');
  input.setAttribute('value', text);
  document.body.appendChild(input);
  input.select();
  var result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
}

export default CopyService;