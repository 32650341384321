/* eslint "react/jsx-no-target-blank": "off" */
// TODOs

// 1. There's something called brace that's like a better version of AceEditor. Looks like Li had tried to use it, or
// is using the styles from it. May be worth digging into.

// 2. On mobile the JSON preview is always hidden currently. Maybe
// provide some kind of tab interface to toggle between the editor
// and the preview?

import React, {Component} from 'react';
import ToolNav from './components/tool-nav/ToolNav';

// should we be using brace? https://github.com/thlorenz/brace
// import brace from 'brace';

import hjson from 'hjson';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/mode/hjson';
import 'brace/mode/html';
import 'brace/theme/ambiance';
import 'brace/theme/github';
import 'brace/theme/monokai';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/react-fontawesome';

import Bend from './bend/BendClient';

import './App.css';
import Modal from "./components/modal/Modal";
import BottomNav from "./components/bottom-nav/BottomNav";
import CopyService from "./services/CopyService";
import WindowService from './services/WindowService';

const defaultCode = `{
  // Welcome! JSON.link makes it easier to author, document,
  // share, and consume JSON.

  // This editor supports Hjson syntax, which makes writing
  // JSON less plainful. See below for examples. The compiled
  // JSON code is shown on the right, and a URL is provided
  // to consume the raw JSON within your app if you'd like.

  # TL;DR

  human:   Hjson
  machine: JSON

  // Use #, // or /**/ comments,

  // Omit quotes for keys
  key: 1

  // Omit quotes for strings
  contains: everything on this line

  // Omit commas at the end of a line
  cool: {
    foo: 1
    bar: 2
  }

  // Allow trailing commas
  list: [
    1,
    2,
  ]

  // And use Python-like multiline strings
  realist:
  '''
  My half empty glass,
  I will fill your empty half.
  Now you are half full.
  '''
}`;

class Home extends Component {
  constructor(props) {
    super(props);

    this.updateTitle = this.updateTitle.bind(this);
    this.saveTitle = this.saveTitle.bind(this);

    this.document = null;
    this.titleInputRef = React.createRef();

    let parseError = '';
    let haveParseError = false;
    let jsonOutput = '';

    this.state = {
      isMobile: window.innerWidth <= 768,
      previewMode: false,
      hjson: '',
      jsonOutput,
      mode: 'json',
      loading: true,
      showPreview: true,
      haveParseError,
      parseError,
      token: this.props.match.params.token || '',
      jsonToken: 'xxxxxxxxxx',
      title: '',
      editingTitle: false
    };

    if (!this.state.token || this.state.token === '') {
      try {
        // Use default exmaple code
        jsonOutput = JSON.stringify(hjson.parse(defaultCode), null, 2);
      } catch (e) {
        jsonOutput = ''; // XXX was defaultCode
        haveParseError = true;
        parseError = e.message;
      }
      this.state = {
        ...this.state,
        hjson: defaultCode,
        jsonOutput: jsonOutput,
        haveParseError: haveParseError,
        parseError: parseError,
        title: 'Untitled'
      };
    } else {
      // console.log(`Loading document ${this.state.token}`)
      Bend.executeAnonymous('getDocument', {token: this.state.token}).then(
        (response) => {
          let document = response.data;
          try {
            jsonOutput = JSON.stringify(hjson.parse(document.code), null, 2);
            // console.log('jsonOutput', jsonOutput);
          } catch (e) {
            jsonOutput = '';
            haveParseError = true;
            parseError = e.message;
          }
          this.setState({
            loading: false,
            mode: document.mode || 'hjson', // XXX What is this for? Can also be 'json'
            hjson: document.code,
            title: document.title,
            jsonOutput: jsonOutput,
            haveParseError: haveParseError,
            parseError: parseError,
            jsonToken: document.jsonToken
          });
          this.document = document;
        },
        (error) => {
          console.log('Request error', error);
          this.setState({
            loading: false
          });
        }
      ).catch((error) => {
        console.log('Error caught', error);
        this.setState({
          loading: false
        });
      });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({isMobile: window.innerWidth <= 768});
    });
  }

  onChange(newVal) {
    this.setState({
      hjson: newVal
    });

    try {
      let jsonOutput = JSON.stringify(hjson.parse(newVal), null, 2);
      this.setState({
        jsonOutput,
        haveParseError: false
      });
    } catch (e) {
      console.log("error", e.message);
      this.setState({
        parseError: e.message,
        haveParseError: true
      });
    }
  }

  changeMode() {
    this.setState({
      showPreview: !this.state.showPreview
    });
  }

  onView() {

  }

  closeModal() {
    this.setState({
      activeModal: undefined,
    });
  }

  displayInitialSave() {
    this.setState({
      activeModal: {
        closeCallback: this.closeModal.bind(this),
        title: 'Document Saved',
        jsx:
          <div>
            <p>Please bookmark this page so you can return to it in the future to continue editing. Anyone with this
              link can edit the document.</p>
            <p>In additional we provide a second link <i className="text-gray">(with a different token in the
              URL)</i> to access the raw JSON output. This is served with Content-Type set to applications/json and with
              appropriate CORS headers so you can use it from your own applications during development. We do not
              recommend relying on JSON.link URLs for production applications.</p>

            <div className="flex column align-center">
              <h3>Document Editor Link</h3>
              <div className="flex justify-center">
                <input readOnly id={"copy-input"} className={"tool-input"}
                       value={`https://json.link/${this.state.token}`}/>
                <button onClick={() => CopyService.copyWindowUrl(this.state.token)}
                        className={"btn-icon input-attach-right btn-solid"}><i className={"far fa-copy"}></i></button>
              </div>
            </div>

            <div className="flex column align-center">
              <h3>JSON Endpoint</h3>
              <div className="flex justify-center">
                <input readOnly id={"copy-input"} className={"tool-input"}
                       value={`https://json.link/${this.state.jsonToken}.json`}/>
                <button onClick={() => CopyService.copyJsonUrl(this.state.jsonToken)}
                        className={"btn-icon input-attach-right btn-solid"}><i className={"far fa-copy"}></i></button>
              </div>
            </div>

          </div>
      }
    });
  }

  displayTellAFriendModal() {
    this.setState({
      activeModal: {
        closeCallback: this.closeModal.bind(this),
        title: 'Tell a friend',
        jsx:
          <div>
            <p>Find this site useful? We’d really appreciate if you helped us spread the word.</p>

            <p>Have feedback, questions, suggestions? We’d love to hear from you! Tweet us <a
              href="https://twitter.com/justshipped" target="_blank">@justshipped</a> or email us at <a
              href="mailto:jsonlink@justshipped.io">jsonlink@justshipped.io</a>.</p>

            <div className="flex column align-center mt-4">
              <button
                className="btn-social btn-social--twitter"
                onClick={() => window.open('https://twitter.com/intent/tweet?url=https%3a%2f%2fjson.link%2f&amp;text=JSON.link+makes+it+easy+to+author,+document,+share,+and+consume+JSON', '_blank')}
                target="_blank"
              ><i className="fab fa-twitter"></i>Share on Twitter
              </button>
              <button
                className="btn-social btn-social--facebook"
                onClick={() => window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href), 'facebook-share-dialog', 'width=626,height=436')}
              ><i className="fab fa-facebook-square"></i>Share on Facebook
              </button>
              {/* <button className="btn-social btn-social--linkedin"><i className="fab fa-linkedin"></i>Share on LinkedIn</button> */}
            </div>
          </div>
      }
    });
  }

  displayAboutModal() {
    this.setState({
      activeModal: {
        closeCallback: this.closeModal.bind(this),
        title: 'About',
        jsx:
          <div>
            <h2>What is this?</h2>
            <p>JSON.link is a power tool for authoring, documenting, sharing, and consuming JSON.</p>

            <h2>Whoa, that’s a mouthful. Come again?</h2>
            <p>With JSON.link you can:</p>

            <ul>
              <li>Quickly author JSON in a web-based editor that supports Hjson syntax. That means you don’t have to
                quote every key, or worry too much about commas. Hjson is JSON for humans. Our split-view editor will
                also show you the compiled JSON in realtime, so you can be confident that it’s getting parsed correctly.
              </li>
              <li>Document a JSON payload with comments! Yes, Hjson supports all your favorite comments styles.</li>
              <li>Share a JSON payload with your colleagues. Your document gets a unique URL once you saved.</li>
              <li>Consume your document as raw JSON from your applications. We send it Content-Type: applications/json.
                Our server will also send the appropriate CORS headers so you can consume your JSON from any site.
              </li>
            </ul>

            <h2>Who made this?</h2>
            <p>This utility is brought to you by <a href="http://codymikol.com/" target="_blank">Cody Mikol</a>, <a
              href="http://kostasnasis.com/" target="_blank">Kostas Nasis</a>, and <a href="http://yingyingzhang.com/"
                                                                                      target="_blank">YingYing Zhang</a>.
              It’s part of an effort we call <a href="http://justshipped.io/" target="_blank">Just Shipped</a>, where we
              pursue rapid learning through unique projects shipped regularly.</p>

            <h2>Questions? Suggestions?</h2>
            Email us at <a href="mailto:jsonlink@justshipped.io">jsonlink@justshipped.io</a>. We’d love to hear from
            you.

          </div>
      }
    });
  }

  renderActiveModal() {
    if (this.state.activeModal) {
      return <Modal closeCallback={() => this.state.activeModal.closeCallback()}
                    title={this.state.activeModal.title}
                    jsx={this.state.activeModal.jsx}>
      </Modal>;
    } else {
      return "";
    }
  }

  clear() {
    this.setState({
      hjson: '',
      jsonOutput: '',
    });
  }

  save() {
    let {hjson, title, mode} = this.state;

    if (this.document) {
      this.document = Object.assign({}, this.document, {
        title,
        code: hjson,
        mode: mode
      });
    } else {
      this.document = {
        title,
        code: hjson,
        mode: mode
      };
    }

    this.setState({
      isSaving: true,
    });

    Bend.executeAnonymous('saveDocument', this.document).then(
      (response) => {
        let doc = response.data;
        // console.log('saved doc', doc);
        this.document = doc;
        this.setState({
          isSaving: false,
          token: doc.token,
          jsonToken: doc.jsonToken
        });
        window.history.pushState('home', 'JSON Builder', `/${doc.token}`);
        this.displayInitialSave();
      }
    ).catch((error) => {
      console.log('Error caught while saving document', error);
      this.setState({
        isSaving: false
      });
    });
  }

  startNewDocument() {
    window.location.href = 'https://json.link';
  }


  editTitle() {
    this.setState({editingTitle: true});
    let that = this;
    setTimeout(() => {
      that.titleInputRef.current.select();
    }, 100);
  }

  saveTitle() {
    this.setState({editingTitle: false});
    if (this.state.token) {

      Bend.executeAnonymous('updateDocumentTitle', {token: this.state.token, title: this.state.title}).then(
        (response) => {
          console.log('Updated title:', response.data.title);
        }
      ).catch((error) => {
        console.log('Error caught while updating document title', error);
      });
    }
  }

  updateTitle(title) {
    this.setState({title: title});
  }

  render() {
    let {hjson, jsonOutput, parseError, haveParseError, showPreview, jsonToken, title, editingTitle} = this.state;
    return (
      <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        {this.renderActiveModal()}
        <div className={"flex column"}>
          <div className="navbar-top">
            <div className="flex row">
              <img src={"assets/logo-json.svg"} className="logo" alt="{ JSON.link }"/>
            </div>
            {!editingTitle && <div className="nav-title" onClick={this.editTitle.bind(this)}>
              <h1>{title}</h1>
              <i className="nav-title-icon fas fa-pen"></i>
            </div>}
            {editingTitle && <div className="nav-title" onClick={() => this.setState({editingTitle: true})}>
              <input
                ref={this.titleInputRef}
                type="text"
                name="title"
                value={title}
                onChange={e => this.updateTitle(e.target.value)}
                onBlur={this.saveTitle}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    this.saveTitle(event);
                  }
                }}
              />
              {this.state.editingTitle ? <i className="nav-title-icon fas fa-check"></i> :
                <i className="nav-title-icon fas fa-pen"></i>}
            </div>}


            <div className={"flex row align-center"}>
              <button className={"top-nav-button"} onClick={() => this.displayTellAFriendModal()}>Share</button>
              <button className={"top-nav-button"} onClick={() => this.displayAboutModal()}>About</button>
              <button className={"top-nav-new-button"} onClick={() => WindowService.newDocument()}><img
                alt="New Document" src={"assets/plus.svg"}/></button>
            </div>
          </div>
          <ToolNav
            save={this.save.bind(this)}
            clear={this.clear.bind(this)}
            isDisabled={this.state.jsonToken === 'xxxxxxxxxx'}
            jsonToken={jsonToken}
            json={jsonOutput}
            title={title}
            updateTitle={(value) => this.updateTitle(value)}
            saveTitle={this.saveTitle}
          />
        </div>
        <div style={{flexGrow: 1}} className="outer-editor-view">
          {
            (!this.state.isMobile || !this.state.previewMode) ? <div style={{
              width: '100%',
              height: '100%',
              fontSize: '14px',
            }} id={"hjson-editor"} className="editor-view">
              <AceEditor
                style={{width: '100%', height: '100%'}}
                mode="hjson"
                theme="github"
                value={hjson}
                onChange={this.onChange.bind(this)}
                name="hjson-editor"
                fontSize={14}
                editorProps={{$blockScrolling: true}}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                setOptions={{
                  enableBasicAutoCompletion: true,
                  enableLiveAutoCompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 2,
                }}/>
            </div> : ''

          }

          {!this.state.isMobile ? <button onClick={this.changeMode.bind(this)} className={"view-expander"}>
            <img className={this.state.showPreview ? "" : "flip-x-scale"} src={"./assets/arrow.svg"}
                 alt="Toggle Preview"/>
          </button> : ""}


          {showPreview && !(this.state.isMobile && !this.state.previewMode) ?
            <div id={"json-editor"} className="editor-view">
              <AceEditor
                style={{width: '100%', height: '100%'}}
                mode={haveParseError ? "html" : "json"}
                readOnly={true}
                theme="ambiance"
                value={haveParseError ? parseError : jsonOutput}
                onChange={this.onChange.bind(this)}
                name="json-editor"
                editorProps={{$blockScrolling: true}}
                fontSize={14}
              />
            </div> : ''
          }
        </div>
        <div className={"desktop-hidden"}>
          <BottomNav isDisabled={this.state.jsonToken === 'xxxxxxxxxx'} jsonToken={this.state.jsonToken}
                     toggleCallback={(previewMode) => this.setState({previewMode: previewMode})}></BottomNav>
        </div>
      </div>
    );
  }
}

export default Home;
