const DownloadService = {
  download: download
};

function download(json, jsonToken) {
  let element = document.createElement('a');
  element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(json));
  element.setAttribute('download', `export-${jsonToken}.json`);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export default DownloadService;