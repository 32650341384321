const WindowService = {
  openToken: openToken,
  newDocument: newDocument
};

function openToken(token) {
  window.open(`/${token}.json`, "_blank");
}

function newDocument() {
  window.open("https://json.link", "_blank")
}

export default WindowService;