import React from 'react';
import './tool-nav.css';
import '../../styles/media.css'
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DownloadService from "../../services/DownloadService";
import CopyService from "../../services/CopyService";
import WindowService from "../../services/WindowService";

class ToolNav extends React.Component {

  constructor(props) {
    super(props);
    //TODO(Mikol): We should extract these out to a service so they don't all have to be passed here.
    this.save = props.save;
    this.clear = props.clear;
    this.state = {
      jsonToken: props.jsonToken,
      title: props.title,
      editingTitle: false
    };
    this.titleInputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.jsonToken !== this.props.jsonToken) {
      this.setState({
        jsonToken: this.props.jsonToken
      })
    }
    if (this.state.title !== this.props.title) {
      this.setState({
        title: this.props.title
      })
    }
  }

  editTitle() {
    this.setState({editingTitle: true})
    setTimeout(() => {
      this.titleInputRef.current.select();
    }, 100);
  }

  saveTitle() {
    console.log('save title called');
    this.props.saveTitle();
    this.setState({editingTitle: false});
  }

  render() {
    const { editingTitle } = this.state;
    return (
      <div className={"tool-nav"}>
        <div className={"flex row mobile-space-between mobile-full-width"}>
          {editingTitle && <label className={"tool-label desktop-hidden"}>
            <input
              ref={this.titleInputRef}
              className={"tool-input"}
              style={{width:'100%'}}
              value={this.state.title}
              onChange={e => this.props.updateTitle(e.target.value)}
              onBlur={this.saveTitle.bind(this)}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.saveTitle()
                }
              }}
            />
            <button className={"btn-icon input-attach-right btn-solid flex justify-center align-center"}><FontAwesomeIcon icon={faCheck} size="xs"/></button>
          </label>}
          {!editingTitle && <label className={"tool-label desktop-hidden"}>
            <span
              className="tool-title"
              onClick={this.editTitle.bind(this)}
            >{this.state.title}<button className="btn-icon-purple flex justify-center"><i className="fas fa-pen"></i></button></span>
          </label>}
          <div className={"mobile-row-reverse flex"} style={{marginRight: 4}}>
            <button className={"btn-long btn-solid"} onClick={() => this.props.save()}>Save</button>
            <button className={"btn-long btn-outlined"} onClick={() => this.props.clear()}>Clear</button>
          </div>
        </div>
        <div className={"flex row align-center mobile-hidden"}>
          <p className="tablet-hidden">{this.props.isDisabled ? 'Save to use this JSON in your app:' : 'Use this in your app:'}</p>
          <input disabled={this.props.isDisabled} readOnly id={"copy-input"} className={"tool-input"} value={`https://json.link/${this.state.jsonToken}.json`}/>
          <button disabled={this.props.isDisabled} onClick={() => CopyService.copyJsonUrl(this.state.jsonToken)} className={"btn-icon btn-icon-title input-attach-right btn-solid"}><i className={"far fa-copy"}></i><span className={"tablet-hidden"}>Copy</span></button>
          <button disabled={this.props.isDisabled}  onClick={() => DownloadService.download(this.props.json, this.state.jsonToken)} className={"btn-icon btn-icon-title btn-solid"}><i className={"fas fa-file-download"}></i><span className={"tablet-hidden"}>Download</span></button>
          <button disabled={this.props.isDisabled}  onClick={() => WindowService.openToken(this.state.jsonToken)} className={"btn-icon btn-icon-title btn-solid"}><i className={"fas fa-external-link-alt"}></i><span className={"tablet-hidden"}>Open</span></button>
        </div>
      </div>
    )
  }

}

export default ToolNav;