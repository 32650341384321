import qs from 'qs'
import config from './config'
import * as httpClient from './httpClient'

// XXX TODO Bend constructor should probably accept a config rather than
// importing one. The structure right now isn't very reusable. We can't
// just include Bend client on a website and intialize it. Or use it in
// the Bend javascript sandbox. This approach requires adding the code
// into each project manually, and invovles having a build system.

class Bend {
  constructor () {
    // init config
    this.credentials = {
      appKey: config.appKey,
      appSecret: config.appSecret
    }
    this.BEND_ENDPOINT = config.bendEndpoint
    this.PUSH_ENDPOINT = config.websocketURL
    this.TOKEN_KEY = 'Bend.token'

    // http tooling with different instances
    this.guestHttp = httpClient.guestInstance
    this.authedHttp = httpClient.authedInstance
    this.Pusher = {
      init: async (receiveMessage, didConnect, didDisconnect, options) => {
        this.webSocket = new window.WebSocket(config.websocketURL);
        this.webSocketSubscription = [];
        this.webSocket.onopen = ()=>
        {
          didConnect();
        };
        this.webSocket.onmessage =  (evt)=>

        {
          var received_msg = evt.data;
          receiveMessage(received_msg);
        };
        this.webSocket.onclose = ()=>
        {
          didDisconnect();
        };
        return {
          status: "OK"
        };
      },

      disconnect: (channel, options) => {
        this.webSocket.close();
        this.init(this.webSocketUrl, options);
      },

      close: () => {
        this.webSocket.close();
      },

      login: async (token, appkey, options) => {
        var data = {event: "pusher.login", data: {id: "1", appKey: appkey, authtoken: token}};
        this.webSocket.send(JSON.stringify(data));
        return {
          status: "OK"
        };
      },

      push: async (event, channel, data, options) => {
        var message = {
          event: "pusher.push",
          data:
          {
            id: Math.floor((Math.random()*1000)+2).toString(),
            event: event,
            channel: channel,
            payload: data
          }
        };
        this.webSocket.send(JSON.stringify(message));
        return {
          status: "OK"
        };
      },

      subscribe: async (channel, options) => {
        var data = {
          event: "pusher.subscribe",
          data:
          {
            id: Math.floor((Math.random()*1000)+2).toString(),
            channel: channel
          }
        };
        this.webSocket.send(JSON.stringify(data));
        return {
          status: "OK"
        };
      },

      unsubscribe: async (channel, options) => {
        var data = {
          event: "pusher.unsubscribe",
          data:
          {
            id: Math.floor((Math.random()*1000)+2).toString(),
            channel: channel
          }
        };
        this.webSocket.send(JSON.stringify(data));
        return {
          status: "OK"
        };
      }
    }
  }



  endpoint (...args) {
    return args.map((arg) => {
      if (arg === true) {
        return this.credentials.appKey
      } else {
        return arg
      }
    }).join('')
  }

  logIn (credentials = { username: null, password: null }) {
    return new Promise((resolve, reject) => {
      this.guestHttp.post(
        this.endpoint('/user/', true, '/login/'),
        JSON.stringify(credentials)
      )
        .then((response) => {
          const token = response.data._bmd.authtoken
          localStorage.setItem(this.TOKEN_KEY, token)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  // XXX The password reset related endpoints are custom endpoints in this
  // particular app right now. They aren't technically part of Bend core,
  // though they probably should be.
  resetPassword (email = null) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    return this.guestHttp.post(
      this.endpoint('/rpc/', true, '/custom/request-password-reset'),
      JSON.stringify({ email }),
      config
    )
  }

  verifyToken (token = null) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    return this.guestHttp.post(
      this.endpoint('/rpc/', true, '/custom/verify-token'),
      JSON.stringify({ token }),
      config
    )
  }

  updatePassword (
    credentials = {
      token: null,
      password: null
    }
  ) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    return this.guestHttp.post(
      this.endpoint('/rpc/', true, '/custom/update-password'),
      JSON.stringify(credentials),
      config
    )
  }

  getUser () {
    return this.authedHttp.get(this.endpoint('/user/', true, '/_me/'))
  }

  logOut () {
    localStorage.removeItem(this.TOKEN_KEY)
  }

  get (collectionName, id = null, q = {}, resolve = '', retainReferences = false) {
    let query = { ...q }

    if (query.query) {
      delete query.query
    }

    let endpoint = this.endpoint(
      (collectionName === 'user'
        ? '/user/'
        : '/appdata/'
      ),
      true,
      '/',
      (collectionName === 'user' ? '' : collectionName + '/'),
      id ? `${id}/` : '',
      q.query
        ? qs.stringify({
          query: JSON.stringify(q.query),
          resolve,
          retainReferences
        }, {
          addQueryPrefix: true
        }) + '&'
        : '',
      qs.stringify(query, {
        addQueryPrefix: false
      })
    )

    return this.authedHttp.get(endpoint, {headers:{
      'Authorization': `Bend ${this.getAuthToken()}`
    }})
  }

  count (collectionName, q = {}) {
    let query = { ...q }

    if (query.query) {
      delete query.query
    }

    let endpoint = this.endpoint(
      '/appdata/',
      true,
      '/',
      collectionName,
      '/_count',
      q.query
        ? qs.stringify({
          query: JSON.stringify(q.query)
        }, {
          addQueryPrefix: true
        }) + '&'
        : '',
      qs.stringify(query, {
        addQueryPrefix: false
      })
    )

    return this.authedHttp.get(endpoint, {headers:{
      'Authorization': `Bend ${this.getAuthToken()}`
    }})
  }

  getAuthToken () {
    return localStorage.getItem(this.TOKEN_KEY)
  }

  createReference (collectionName, collectionId) {
    if (!collectionId) {
      return undefined
    }
    return {
      '_type': 'BendRef',
      '_id': collectionId,
      '_collection': collectionName
    }
  }

  createFileReference (collectionName, collectionId) {
    if (!collectionId) {
      return undefined
    }
    return {
      '_type': 'BendFile',
      '_id': collectionId,
      '_collection': collectionName
    }
  }

  getFile (id = null) {
    const endpoint = this.endpoint(
      '/blob/',
      true,
      '/',
      id ? `${id}/` : ''
    )

    return this.authedHttp.get(endpoint, {headers:{
      'Authorization': `Bend ${this.getAuthToken()}`
    }})
  }

  create (collectionName, data) {
    let endpoint = this.endpoint(
      '/appdata/',
      true,
      '/',
      collectionName,
      '/'
    )

    delete data.createdAt

    if (collectionName === 'user') {
      endpoint = this.endpoint(
        '/user/',
        true,
        '/'
      )

      return this.guestHttp.post(endpoint, data, {headers:{
        'Authorization': `Bend ${this.getAuthToken()}`
      }})
    } else {
      return this.authedHttp.post(endpoint, data, {headers:{
        'Authorization': `Bend ${this.getAuthToken()}`
      }})
    }
  }

  update (collectionName, id, data) {
    let endpoint = this.endpoint(
      '/appdata/',
      true,
      '/',
      collectionName,
      '/',
      id ? `${id}/` : ''
    )

    delete data.createdAt

    if (collectionName === 'user') {
      endpoint = this.endpoint(
        '/user/',
        true,
        '/',
        id ? `${id}/` : ''
      )
    }

    return this.authedHttp.put(endpoint, data, {headers:{
      'Authorization': `Bend ${this.getAuthToken()}`
    }})
  }

  createFile (file, workflowOption = '') {
    const endpoint = this.endpoint(
      '/blob/',
      true,
      '/?tls=true'
    )

    const workflow = workflowOption
      ? { _workflow: workflowOption }
      : {}

    const metadata = {
      _filename: file.name,
      mimeType: file.type,
      size: file.size,
      _public: true,
      ...workflow
    }

    return this.authedHttp.post(endpoint, metadata, {headers:{
      'Authorization': `Bend ${this.getAuthToken()}`
    }})
  }

  uploadFile (url, file, onUploadProgress) {
    const config = {
      headers: {
        'Content-Type': file.type,
        'Authorization': `Bend ${this.getAuthToken()}`
      },
      onUploadProgress
    }

    return this.authedHttp.put(url, file, config)
  }

  executeAnonymous (endpointname, param) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    return this.guestHttp.post(
      this.endpoint('/rpc/', true, `/custom/${endpointname}`),
      JSON.stringify(param),
      config
    )
  }

  async createAndUploadFile (file, onUploadProgress, workflow) {
    const fileCreateResponse = await this.createFile(file, workflow)

    const uploadUrl = fileCreateResponse.data._uploadUrl

    return new Promise(async (resolve, reject) => {
      try {
        await this.uploadFile(uploadUrl, file, onUploadProgress)
        resolve(fileCreateResponse.data)
      } catch (err) {
        reject(err)
      }
    })
  }

  async delete (collectionName, id = null) {
    const existedItemResponse = await this.get(collectionName, id)

    return this.update(collectionName, id, {
      ...existedItemResponse.data,
      deleted: true
    })
  }
}

if (window) {
  window.Bend = new Bend()
}

export default new Bend()
