import config from './config'
import axios from 'axios'
import { Base64 } from 'js-base64'

/* Request headers */

const commonHeaders = {
  'Content-Type': 'application/json; charset=utf-8',
  'X-Bend-API-Version': '3'
}

const guestHeaders = {
  Authorization: 'Basic ' + Base64.encode(config.appKey + ':' + config.appSecret)
}

/* Instances */

export const guestInstance = axios.create({
  baseURL: config.bendEndpoint,
  headers: {
    ...commonHeaders,
    ...guestHeaders
  }
})

export const authedInstance = axios.create({
  baseURL: config.bendEndpoint,
  headers: {
    ...commonHeaders
  }
})
