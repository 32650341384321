import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './bottom-nav.css';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import WindowService from "../../services/WindowService";

class BottomNav extends React.Component {

  constructor(props) {
    super(props);
    this.toggleRef = React.createRef();
    this.toggleCallback = props.toggleCallback;
  }

  toggleButton() {
    this.toggleRef.current.classList.toggle('active');
    this.toggleCallback(this.toggleRef.current.classList.contains('active'))
  }

  render() {
    return (
      <div className={"bottom-nav-container"}>
        <div onClick={this.toggleButton.bind(this)} className={"toggle-container"}>
          <div className={"bottom-nav-slider-text"}>
            <p>Edit</p>
            <p>Preview</p>
          </div>
          <div className={"toggle-slider"} ref={this.toggleRef}></div>
        </div>
        <div>
          {/*<button disabled={this.props.isDisabled}  onClick={() => CopyService.copyJsonUrl(this.props.jsonToken)} className={"btn-icon btn-outlined text-small"} style={{background: '#3C3C3C', border: 'none'}}>*/}
          {/*  <FontAwesomeIcon icon={faCopy}/><span>Copy</span>*/}
          {/*</button>*/}
          <button disabled={this.props.isDisabled} onClick={ () => WindowService.openToken(this.props.jsonToken)} className={"btn-icon btn-outlined text-small"} style={{background: '#3C3C3C', border: 'none'}}>
            <FontAwesomeIcon icon={faExternalLinkAlt}/><span>Open JSON Endpoint</span>
          </button>
        </div>
      </div>
    );
  }

}

export default BottomNav;