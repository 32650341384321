import React from 'react';
import './modal.css';

class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.trapRef = React.createRef();
    this.bodyRef = React.createRef();
    this.closeCallback = props.closeCallback;
    this.title = props.title;
    this.jsx = props.jsx;
  }

  closeModal() {
    [this.trapRef.current, this.bodyRef.current]
      .forEach(e => e.classList.remove('active'));
    setTimeout(() => {
      this.closeCallback();
    }, 200)
  }

  render() {
    return (
      <div className={"modal-container"}>
        <div onClick={() => this.closeModal()} className={"modal-trap"} ref={this.trapRef}></div>
        <div className={"modal-body"} ref={this.bodyRef}>
          <div className={"modal-header"}>
            <h2 className={"modal-title"}>{this.props.title}</h2>
            <button className={"btn-close"} onClick={() => this.closeModal()}><i className={"fas fa-times"}></i></button>
          </div>
          <div className={"modal-border"}></div>
          <div className={"modal-content"}>
            {this.props.jsx}
          </div>
          <div className={"modal-border"}></div>
          <p class="modal-footer">JSON.link is a <a href="http://justshipped.io/">Just Shipped</a> project</p>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      [this.trapRef.current, this.bodyRef.current]
        .forEach(e => e.classList.add('active'))
    }, 0);
  }

}

export default Modal;